.Pricing-Content {
  width: 100%;
  position: relative;
  top: -80px;

  .RowContent {
    display: flex;
    max-width: 1300px;
    margin: auto;
    padding: 0 2em;
    @media (min-width: 870px) { padding: 0 4em; }

    .copy {
      z-index: 2
    }

    h2 {
      color: $levarBlack;
      font-size: 2em;
      margin: 0;
      line-height: 1;
      @media (min-width: 420px) { font-size: 38px; }
      @media (min-width: 992px) { font-size: 45px; }
      @media (min-width: 1170px) { font-size: 55px; }
    }

    p {
      color: $levarBlack;
      font-size: 1.2em;
      @media (min-width: 1120px) { font-size: 1.5em; }
    }

    .list {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          font-size: 1.25em;
          line-height: 40px;

          span {
            color: $levarRed;
            font-weight: 700;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .RowCallOut {
    width: 100%;
    height: 100px;
    display: flex;
    padding: 20px;
    background: linear-gradient(to right, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
    align-items: center;
    background-size: cover;
    justify-content: center;
    margin-top: 50px;
  }

  .RowOne {
    position: relative;
    margin-top: 100px;
    // @media (min-width: 870px) { margin-bottom: 200px; margin-top: 100px; }
    // @media (min-width: 960px) { margin-bottom: 250px; }

    &::before {
      content: "";
      height: 0px;
      background: -webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
      left: 0;
      position: absolute;
      z-index: 2;
      top: 9px;
      margin-top: 20px;
      @media (min-width: 1120px) { height: 4px; width: 3em; }
      @media (min-width: 1370px) { height: 4px; width: 7%; }
    }

    .RowContent {
      flex-wrap: wrap;

      h2 {
        @media (max-width: 870px) {
          position: relative;
          padding-left: 1em;
          &::before {
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            content: '';
            position: absolute;
            background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
          }
        }
        width: 100%;
        @media (min-width: 870px) { margin-top: 20px; width: 150%; }
      }

      .copy {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 2;
        flex: 0 1 100%;
        position: relative;
        z-index: 99;
        margin-bottom: 2em;
        @media (min-width: 870px) { flex: 1; margin-bottom: 0px;}

        .copyContent {
          width: 100%;
          @media (min-width: 870px) { width: 95%; }
        }

      }

      .imagery {
        position: relative;
        width: 100%;
        flex: 1 0;
        // display: none;
        @media (min-width: 870px) { flex: 1.4; display: block;  }

        &::after {
          content: "";
          background:url("../../assets/images/home/stepTexture.png");
          position: absolute;
          z-index: 1;
          top: 50%;
          right: -45%;
          width: 300px;
          height: 97%;
          background-size: contain;
          transform: translateY(-50%);
          display: none;
          @media (min-width: 870px) { top: 50%; left: -12%; width: 300px; height: 60%; display: block; }
          @media (min-width: 1120px) { height: 90%; top: 50%; left: -15%; opacity: .75; width: 500px; display: block; }
        }

        img {
          width: 100%;
          max-width: 500px;
          z-index: 3;
          position: relative;
          box-shadow: 0px 0px 12px 2px #0000001c;
        }
      }

      .list {
        z-index: 3;
        display: flex;
        flex-direction: column;
        position: relative;
        left: 0%;
        justify-content: space-evenly;
        width: 100%;
        flex: 1 0;
        @media (min-width: 870px) { flex: 1; left: -3%; justify-content: center; }

        .listContent {
          display: flex;
          align-items: center;
          padding-bottom: .65em;
          width: 100%;
          @media (min-width: 1120px) { width: 120%; padding-bottom: 2.5em; }

          &:first-child span { background: $levarBlue; }
          &:nth-child(2) span { background: $levarRed; }
          &:nth-child(3) span { background: $levarRed; }

          span {
            border-radius: 50%;
            height: 55px;
            width: 55px;
            padding: 15px;
            color: #fff;
            font-size: 1.1em;
            font-weight: bold;
            display: flex;
            align-items: center;
          }

          p {
            margin: 0;
            padding-left: 1em;
            font-size: 1em;
            @media (min-width: 1120px) { font-size: 1.2em; }
          }
        }
      }
    }
  }

}
