
/* ############################ */
/* #### STYLE GUIDE #START #### */

// Fonts
$fontOverpass: 'Overpass', sans-serif;

// font colors
$levarWhite: #fff; // white
$levarCTA: #ff3451; // red
$levarBlack: #1f1f1f; // black


// gradient
$levarBlue: #0099ba;
$levarDarkBlue: #00334e;
$levarRed: #ff3451;

h1 {
  font-family: $fontOverpass;
}


// Mixins
@mixin levarTitle {
  display: -webkit-flex;
  display: flex;
  // font-family: $fontBebasRegular;
  // color: $levarWhite;
}

@mixin levarTitle5 {
  display: -webkit-flex;
  display: flex;
  // font-family: $fontBebasRegular;
  // color: $levarWhite;
  margin: 0;
  font-size: 1.5em;
  letter-spacing: .04em;
}

/* #### Media Queries Styling #### */
$breakpoint-desktop: 960px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 320px;

$width-large: 1224px;
$width-mediumLarge: 1075px;
$width-medium: 960px;


@media (max-width: $width-mediumLarge) {
  .container {
    .BlogPost {
      width: 100%;
      padding: 2em;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}




// // Header colors
// $HeaderBG: #2d2d2d;
//
// // Footer colors
// $FooterBG: #2d2d2d;
//
// // sidebar colors
// $sidebarBaseColor: #2d2d2d;
// $sidebarAccentColor: #4c4c4c;
//
// // main colors
// $mainColor: #dee9ee;
// $progressbarColor: #2196f378;
//
// // button colors
// $primaryBtnColor: #2196F3;
