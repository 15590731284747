.Blog {

  a {
    text-decoration: none;
  }

  .BlogLink {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    .BlogLink-Image {
      width: 100%;
      height: 240px;
      background-position: 50% 50%;
      background-size: cover;
      margin-bottom: 1em;
      border-radius: 3px;
      border: solid 1px #e4e4e4;
      box-shadow: 2px 2px 5px 0px #0000001f;
    }

    .BlogLink-Copy {
      position: relative;

      h2 {
        color: #4c4c4c;
        font-size: 1.5em;
        // font-family: $fontRobotoCond;
        font-weight: 300;
        // text-transform: uppercase;
        margin: 0;
      }

      p {
        color: #4c4c4c;
        margin: 0;
        font-size: 1em;
        // font-family: $fontRoboto;
        font-weight: 300;
        line-height: 1.45em;
      }
    }
  }
}
