.Viewer {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 9;

  canvas {
    // min-height: 400px;
    outline: none;
  }
}
