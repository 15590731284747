.ARCommerce-Content {
  width: 100%;
  position: relative;

  .RowContent {
    display: flex;
    max-width: 1300px;
    margin: auto;
    padding: 0 2em;
    @media (min-width: 870px) { padding: 0 4em; }

    .copy {
      z-index: 2
    }

    h2 {
      color: $levarBlack;
      font-size: 2em;
      margin: 0;
      line-height: 1;
      @media (min-width: 420px) { font-size: 38px; }
      @media (min-width: 992px) { font-size: 45px; }
      @media (min-width: 1170px) { font-size: 55px; }
    }

    p {
      color: $levarBlack;
      font-size: 1.2em;
      @media (min-width: 1120px) { font-size: 1.5em; }
    }

    .list {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          font-size: 1.25em;
          line-height: 40px;

          span {
            color: $levarRed;
            font-weight: 700;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .RowOne {
    margin-bottom: 100px;
    @media (min-width: 870px) { margin-bottom: 200px; margin-top: 100px; }
    @media (min-width: 960px) { margin-bottom: 250px; }

    &::before {
      content: "";
      height: 0px;
      background: -webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
      left: 0;
      position: absolute;
      z-index: 2;
      top: 9px;
      margin-top: 20px;
      @media (min-width: 1120px) { height: 4px; width: 3em; }
      @media (min-width: 1370px) { height: 4px; width: 7%; }
    }

    .RowContent {
      flex-wrap: wrap;

      h2 {
        @media (max-width: 870px) {
          position: relative;
          padding-left: 1em;
          &::before {
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            content: '';
            position: absolute;
            background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
          }
        }
        @media (min-width: 870px) { margin-top: 20px; width: 150%; }
      }

      .copy {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 2;
        flex: 0 1 100%;
        position: relative;
        z-index: 9;
        @media (min-width: 870px) { flex: 1; }
      }

      .imagery {
        position: relative;
        width: 100%;
        width: 50%;
        flex: 1 0;
        display: none;
        @media (min-width: 870px) { flex: 1.4; display: block;  }

        &::after {
          content: "";
          background:url("../../assets/images/home/stepTexture.png");
          position: absolute;
          z-index: 1;
          top: 56%;
          right: -45%;
          width: 300px;
          height: 97%;
          background-size: contain;
          transform: translateY(-50%);
          @media (min-width: 870px) { top: 59%; left: -12%; width: 300px; height: 60%; }
          @media (min-width: 1120px) { height: 90%; top: 65%; left: -15%; opacity: .75; width: 500px; }
        }

        img {
          width: 100%;
          max-width: 500px;
          z-index: 3;
          position: relative;
          box-shadow: 0px 0px 12px 2px #0000001c;
        }
      }

      .list {
        z-index: 3;
        display: flex;
        flex-direction: column;
        position: relative;
        left: 0%;
        justify-content: space-evenly;
        width: 100%;
        flex: 1 0;
        @media (min-width: 870px) { flex: 1; left: -3%; justify-content: center; }

        .listContent {
          display: flex;
          align-items: center;
          padding-bottom: .65em;
          width: 100%;
          @media (min-width: 1120px) { width: 120%; padding-bottom: 2.5em; }

          &:first-child span { background: $levarBlue; }
          &:nth-child(2) span { background: $levarRed; }
          &:nth-child(3) span { background: $levarRed; }

          span {
            border-radius: 50%;
            height: 55px;
            width: 55px;
            padding: 15px;
            color: #fff;
            font-size: 1.1em;
            font-weight: bold;
            display: flex;
            align-items: center;
          }

          p {
            margin: 0;
            padding-left: 1em;
            font-size: 1em;
            @media (min-width: 1120px) { font-size: 1.2em; }
          }
        }
      }
    }
  }

  .RowTwo {
    position: relative;
    margin-bottom: 100px;
    @media (min-width: 870px) { margin-bottom: 200px; }
    @media (min-width: 960px) { margin-bottom: 250px; }

    &::before {
      content: "";
      background:url("../../assets/images/home/stepTexture.png");
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 0%;
      width: 350px;
      height: 300px;
      transform: translateY(-50%);
      background-size: cover;
      opacity: .75;
      display: none;
      @media (min-width: 870px) { display: block; }
    }

    &::after {
      content: "";
      background:url("../../assets/images/home/tryItOut.jpg");
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 0%;
      width: 200px;
      height: 400px;
      overflow: hidden;
      transform: translateY(-50%);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: solid 6px;
      border-image: linear-gradient(to left, rgba(255,52,81,1), rgba(0,51,78,1)) 1 10%;
      display: none;
      @media (min-width: 960px) { display: block; }
    }

    .RowContent {
      display: flex;
      flex-direction: column;
    }

    .RowTop {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      z-index: 2;
      flex-direction: column;

      @media (min-width: 670px) { flex-direction: row; }

      .title {
        padding-bottom: 1em;
        @media (min-width: 670px) { align-self: flex-end; }

        h2 {
          position: relative;
          padding-left: 1em;

          &::before {
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            content: '';
            position: absolute;
            background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
          }
        }
      }

      .cta {
        background: $levarRed;
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 1em;
        justify-content: space-between;
        @media (min-width: 870px) { border-radius: 25px; padding: 1.1em 2em; }

        img {
          max-width: 80px;
          width: 100%;
          margin-right: 3em;
          display: none;
          @media (min-width: 870px) { display: block; }
        }

        a {
          background: #fff;
          color: $levarRed;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 700;
          border-radius: 22px;
          line-height: 1;
          padding: 13px 22px;
          font-size: 12px;
          letter-spacing: .01em;
          border: solid 2px transparent;
          transition: .3s ease-in-out;
          @media (min-width: 670px) { font-size: 15px; }

          &:hover {
            background: $levarRed;
            color: #fff;
            border: solid 2px #fff;
          }
        }
      }
    }

    .RowBottom {
      z-index: 2;
      display: flex;
      padding-top: 1em;
      @media (min-width: 960px) { padding-top: 2em; }

      .list {
        position: relative;
        right: 0;
        @media (min-width: 960px) { right: 10%; }
        @media (min-width: 1200px) { right: 9%; }

        li {
          padding-bottom: 1em;
          font-size: 1.1em;
          display: flex;
          @media (min-width: 870px) { font-size: 1.25em; line-height: 45px; }
          @media (min-width: 960px) { font-size: 1em; line-height: 35px; padding-bottom: 0em; }
          @media (min-width: 1200px) { font-size: 1.25em; line-height: 40px;  }

          span {
            font-size: 1.5em;
          }
        }
      }

      .circles {
        display: none;
        @media (min-width: 960px) { display: inline-block; }
        img {
          max-width: 250px;
          @media (min-width: 1200px) { max-width: 300px; }
        }
      }
    }
  }

  .RowThree {
    position: relative;
    margin-bottom: 100px;
    @media (min-width: 870px) { margin-bottom: 200px; }
    @media (min-width: 960px) { margin-bottom: 250px; }

    &::before {
      content: "";
      width: 5%;
      height: 0px;
      background: #f4f4f4;
      left: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      @media (min-width: 992px) { height: 4px; }
    }

    &::after {
      content: "";
      height: 0px;
      background: -webkit-linear-gradient(left, rgba(255,52,81,.90) 0%, rgba(0,51,78,.95) 100%);
      right: 0;
      position: absolute;
      z-index: 2;
      top: 47%;
      @media (min-width: 1120px) { height: 4px; width: 15%; }
    }

    .RowContent {
      display: flex;
      flex-direction: column-reverse;
      @media (min-width: 870px) { flex-direction: row; }

      .imagery {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background: -webkit-linear-gradient(top, rgba(255,52,81,1) 0%, rgba(0,51,78,1) 100%);
          width: 4px;
          height: 35%;
          left: 0;
          top: -5%;
          z-index: 9;
          display: none;
          @media (min-width: 870px) { display: block; }
        }

        &::after {
          content: "";
          background:url("../../assets/images/home/stepTexture.png");
          position: absolute;
          z-index: 1;
          top: 50%;
          height: 90%;
          transform: translateY(-50%);
          left: 20%;
          width: 300px;
          background-size: contain;
          opacity: .75;
          display: none;
          @media (min-width: 870px) { width: 500px; left: 50%; display: block; }
        }

        .LevAR-Viewer {
          position: relative;
          width: 100%;
          height: 250px;
          margin: auto;
          @media (min-width: 870px) { margin-top: 2em; width: 450px; height: 450px; }
          @media (min-width: 870px) { margin-top: 0; width: 300px; height: 300px; }
          @media (min-width: 960px) { width: 400px; height: 400px; }
          @media (min-width: 1120px) { width: 500px; height: 500px; }

          .Viewer {
            position: relative;
            height: 100%;
            width: 100%;
            cursor: pointer;
            z-index: 2;
          }
        }

        .rowImage {
          width: 100%;
          z-index: 3;
          position: relative;
          box-shadow: 1px 1px 2px 1px #00000021;
          display: none;
          @media (min-width: 870px) { max-width: 400px; display: block; }
          @media (min-width: 1120px) { max-width: 500px;}
        }

        .Viewer-Link {
          display: flex;
          position: absolute;
          right: -1em;
          top: -1em;
          z-index: 9;
          padding: 11px;
          cursor: pointer;
          border-radius: 50%;
          background: $levarBlue;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          @media (min-width: 870px) { bottom: -1em; top: auto; }

          img {
            width: 30px;
            height: 30px;
            line-height: 1;
          }
        }
      }

      .copy {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-grow: 1;

        h2 {
          text-align: left;
          @media (max-width: 870px) {
            position: relative;
            padding-left: 1em;
            &::before {
              top: 0;
              left: 0;
              width: 4px;
              height: 100%;
              content: '';
              position: absolute;
              background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
            }
          }

          @media (min-width: 870px) { text-align: right; }
        }
      }

      .list {
        display: flex;
        padding: 2em 0 0 0;
        @media (min-width: 870px) { padding: 2em 0 0 2em; justify-content: center; }

        li {
          display: flex;
          align-items: center;
          padding-bottom: 1em;
          font-size: 1.1em;
          @media (min-width: 870px) { font-size: 1.5em; }
        }

        span {
          font-size: 1.5em;
        }
      }

    }
  }

  .RowFour {
    margin-bottom: 100px;
    @media (min-width: 960px) { margin-bottom: 200px; }

    .RowContent {
      display: flex;
      flex-direction: column;
      @media (min-width: 870px) { flex-direction: row; }

      .copy {
        flex: 1;

        .title {
          @media (min-width: 870px) { padding-top: 3em; }
        }

        h2 {
          position: relative;
          padding-left: 1em;
          @media (min-width: 870px) { width: 110%; }

          &::before {
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            content: '';
            position: absolute;
            background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
          }
        }

        .copyContent {
          padding: 0 0px 2em 0px;
          @media (min-width: 640px) { padding: 0 25px 0 50px; }
        }

        p {
          font-size: 1.1em;
          line-height: 1.5em;
          padding: 1em 0;
        }
      }

      .box-container {
        position: relative;
        @media (min-width: 640px) { flex: 1.2; }

        &::before {
          content: "";
          background:url("../../assets/images/home/stepTexture.png");
          position: absolute;
          z-index: 1;
          background-size: contain;
          opacity: .75;
          width: 75%;
          height: 75%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .box-content {
          display: flex;
          align-content: center;
          justify-content: center;
          position: relative;
          z-index: 9;
          flex-direction: column;
          @media (min-width: 640px) { flex-direction: row; }
        }

        .box-left {
          display: flex;
          align-items: center;
          flex: 1;
        }

        .box-right {
          display: flex;
          flex-direction: column;
          flex: 1;
          @media (min-width: 640px) { align-items: center; flex: 1.4; }
        }

        .arrow-container {
          display: flex;
          .arrow {
            height: auto;
            &.down { -webkit-transform: rotateX(180deg); transform: rotateX(180deg); }
          }
        }

        .box-copy {
          color: $levarWhite;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: flex-end;

          h3 {
            margin: 0;
            align-self: flex-end;
            text-align: right;
            font-weight: 400;
            padding-bottom: 10px;
            position: relative;
            margin-bottom: 2em;

            &::after {
              content: "";
              position: absolute;
              width: 75%;
              height: 8%;
              border-radius: 5px;
              background: #bfbfbf;
              bottom: 0;
              right: 0;
            }
          }

          h6 {
            margin: 0;
            align-self: flex-end;
            text-align: right;
            line-height: 1;
            .countup {
              font-weight: 700;
            }
            span {
              font-weight: 300;
            }
          }
        }

        .box {
          background: $levarDarkBlue;
          display: flex;
          justify-content: space-between;
          box-shadow: 1px 1px 2px 1px #00000021;
          @media (max-width: 640px) { max-width: 300px; width: 100%; }

          &.one {
            margin-right: 1em;
            width: 100%;
            padding: 1.5em;
            .arrow-container {
              .arrow {
                width: 50%;
              }
            }
            h3 { font-size: 1.5em; }
            h6 { line-height: 30px; font-size: 50px; }
          }

          &.two {
            padding: 1em;
            align-self: flex-start;
            margin-bottom: 2em;
            margin-top: 2em;
            @media (min-width: 640px) { margin-bottom: 1em; width: 50%; }
            @media (min-width: 870px) { width: 75%; }

            .arrow-container {
              .arrow {
                width: 65%;
              }
            }
            h3 { font-size: 1em; }
            h6 { line-height: 1; font-size: 40px; }
          }

          &.three {
            width: 100%;
            padding: 2em;
            .arrow-container {
              .arrow {
                width: 70%;
              }
            }
            h3 { font-size: 2em; }
            h6 { line-height: 40px; font-size: 75px; }
          }

          &.one, &.two, &.three {
             @media (max-width: 640px) {
               width: 100%;
               max-width: 300px;
               padding: 1em;
               .arrow-container {
                 .arrow {
                   width: 50%;
                 }
               }
               h3 { font-size: 1em; }
               h6 { line-height: 1; font-size: 40px; }
             }
          }
        }
      }
    }
  }
}
