.FeatureRightFadeBoxes-Content {
    width: 100%;
    position: relative;

    .RowFour {
      margin-bottom: 50px;
      @media (min-width: 960px) { margin-bottom: 100px; }
  
      .RowContent {
        display: flex;
        flex-direction: column;
        @media (min-width: 870px) { flex-direction: row; }
  
        .copy {
          flex: 1;
          
          @media (max-width: 870px) { padding-top: 50px;}

          .title {

            @media (min-width: 870px) { padding-top: 3em; }
          }
  
          h2 {
            position: relative;
            padding-left: 1em;

            @media (min-width: 870px) { width: 110%; }
  
            &::before {
              top: 0;
              left: 0;
              width: 4px;
              height: 100%;
              content: '';
              position: absolute;
              background: -webkit-linear-gradient(top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
            }
          }
  
          .copyContent {
            padding: 1em 0px 2em 0px;
            @media (min-width: 640px) { padding: 0 25px 0 50px; }
          }
  
          p {
            font-size: 1.1em;
            line-height: 1.5em;
            padding: 1em 1em 1em 0;
          }
        }
  
        .box-container {
          position: relative;
          @media (min-width: 640px) { flex: 1.2; }
  
          &::before {
            content: "";
            background:url("../../../assets/images/home/stepTexture.png");
            position: absolute;
            z-index: 1;
            background-size: contain;
            opacity: .75;
            width: 75%;
            height: 75%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
  
          .box-content {
            display: flex;
            align-content: center;
            justify-content: center;
            position: relative;
            z-index: 9;
            flex-direction: column;
            @media (min-width: 640px) { flex-direction: row; }
          }
  
          .box-left {
            display: flex;
            align-items: center;
            flex: 1;
          }
  
          .box-right {
            display: flex;
            flex-direction: column;
            flex: 1;
            @media (min-width: 640px) { align-items: center; flex: 1.4; }
          }
  
          .arrow-container {
            display: flex;
            .arrow {
              height: auto;
              &.down { -webkit-transform: rotateX(180deg); transform: rotateX(180deg); }
            }
          }
  
          .box-copy {
            color: $levarWhite;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: flex-end;
  
            h3 {
              margin: 0;
              align-self: flex-end;
              text-align: right;
              font-weight: 400;
              padding-bottom: 10px;
              position: relative;
              margin-bottom: 2em;
  
              &::after {
                content: "";
                position: absolute;
                width: 75%;
                height: 8%;
                border-radius: 5px;
                background: #bfbfbf;
                bottom: 0;
                right: 0;
              }
            }
  
            h6 {
              margin: 0;
              align-self: flex-end;
              text-align: right;
              line-height: 1;
              .countup {
                font-weight: 700;
              }
              span {
                font-weight: 300;
              }
            }
          }
  
          .box {
            background: $levarDarkBlue;
            display: flex;
            justify-content: space-between;
            box-shadow: 1px 1px 2px 1px #00000021;
            @media (max-width: 640px) { max-width: 300px; width: 100%; }
  
            &.one {
              margin-right: 1em;
              width: 100%;
              padding: 1.5em;
              .arrow-container {
                .arrow {
                  width: 50%;
                }
              }
              h3 { font-size: 1.5em; }
              h6 { line-height: 30px; font-size: 50px; }
            }
  
            &.two {
              padding: 1em;
              align-self: flex-start;
              margin-bottom: 2em;
              margin-top: 2em;
              @media (min-width: 640px) { margin-bottom: 1em; width: 50%; }
              @media (min-width: 870px) { width: 75%; }
  
              .arrow-container {
                .arrow {
                  width: 65%;
                }
              }
              h3 { font-size: 1em; }
              h6 { line-height: 1; font-size: 40px; }
            }
  
            &.three {
              width: 100%;
              padding: 2em;
              .arrow-container {
                .arrow {
                  width: 70%;
                }
              }
              h3 { font-size: 2em; }
              h6 { line-height: 40px; font-size: 75px; }
            }
  
            &.one, &.two, &.three {
               @media (max-width: 640px) {
                 width: 100%;
                 max-width: 300px;
                 padding: 1em;
                 .arrow-container {
                   .arrow {
                     width: 50%;
                   }
                 }
                 h3 { font-size: 1em; }
                 h6 { line-height: 1; font-size: 40px; }
               }
            }
          }
        }
      }
    }
  }
  