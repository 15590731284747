.Home {
  #cursor {
    border-left: 3px solid #ffffff;
    animation: blink .8s steps(1) infinite;
    margin-left: 8px;
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
}


.FeatureSteps {
  width: 100%;

  .Step {
    width: 100%;
    position: relative;
    margin: 4em 0;
    padding-bottom: 4em;

    &.Left {
      &::before {
        content: "";
        width: 5%;
        height: 0px;
        background: #f4f4f4;
        left: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        @media (min-width: 992px) { height: 4px; }
      }
      &::after {
        content: "";
        width: 10%;
        height: 0px;
        background: -webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
        right: 0;
        position: absolute;
        z-index: 2;
        top: 35%;
        @media (min-width: 992px) { height: 4px; }
      }

      .featureLeft {}
      .featureRight {
        flex: 1;
        text-align: left;
        @media (min-width: 768px) { text-align: right;  }
      }
      .copy {
        @media (min-width: 768px) { padding-left: 40px; }
      }
      .copyContent {
        padding-right: 0;
        @media (min-width: 992px) { padding-right: 5em; }
      }
      a, p {
        align-self: flex-start;
        @media (min-width: 768px) { align-self: flex-end;; }
      }
      .stepLabel {
        right: -30px;
        @media (min-width: 420px) { right: -65px; }
      }

      .stepImage {
        &::before { left: 0; }
        &::after {
          right: -25px;
          @media (min-width: 420px) { right: -100px; }
          @media (min-width: 768px) { right: -200px; }
        }
      }

    }

    &.Right {
      &::before {
        content: "";
        width: 5%;
        height: 0px;
        background: #f4f4f4;
        right: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        @media (min-width: 992px) { height: 4px; }
      }
      &::after {
        content: "";
        width: 10%;
        height: 0px;
        background: -webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
        left: 0;
        position: absolute;
        z-index: 2;
        top: 29%;
        @media (min-width: 992px) { height: 4px; }
      }

      .StepContent {
        flex-direction: column-reverse;
        @media (min-width: 768px) { flex-direction: row; }
      }

      .featureLeft { flex: 1; text-align: left;}
      .featureRight {}
      .copy {
        @media (min-width: 768px) { padding-right: 40px; }
      }
      .copyContent {
        padding-left: 0em;
        @media (min-width: 992px) { padding-left: 5em; }
      }
      a, p { align-self: flex-start; }
      .stepLabel {
        right: -25px;
        @media (min-width: 420px) { right: -65px; }
        @media (min-width: 768px) { left: -75px; }
      }

      .stepImage {
        &::before { right: 0; }
        &::after {
          right: -25px;
          @media (min-width: 420px) { right: -100px; }
          @media (min-width: 768px) { left: -200px; }
        }
      }

    }
  }

  .StepContent {
    display: flex;
    max-width: 1300px;
    margin: auto;
    padding: 0 2em;
    flex-direction: column;
    @media (min-width: 768px) { flex-direction: row; }
    @media (min-width: 992px) { padding: 0 4em; }

    .copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 9;
      min-width: 300px;
      padding-top: 40px;
      @media (min-width: 768px) { padding-top: 0px; min-width: 419px; }
      @media (min-width: 992px) { min-width: 519px; }

      h2 {
        color: $levarBlack;
        font-size: 34px;
        margin: 0;
        line-height: 1;
        @media (min-width: 992px) { font-size: 45px; }
      }

      .copyContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-start;
        @media (min-width: 768px) { justify-content: center; }
      }

      p {
        color: $levarBlack;
        font-size: 17px;
        @media (min-width: 768px) { flex-direction: row; }
        @media (min-width: 992px) { font-size: 20px; max-width: 400px; }
      }
    }

    .imagery {

      .stepLabel {
        font-size: 70px;
        font-weight: 100;
        line-height: 1;
        letter-spacing: -5px;
        position: absolute;
        font-size: 85px;
        top: -30px;
        z-index: 2;
        background: -webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .stepCallOut {
        width: 100%;
        max-width: 500px;
        z-index: 3;
        position: relative;
        box-shadow: 1px 1px 2px 1px #00000021;
      }
    }

    .stepImage {
      position: relative;
      max-width: 300px;
      width: 100%;
      @media (min-width: 768px) { max-width: 500px;}

      &::before {
        content: "";
        width: 6px;
        height: 37%;
        background: -webkit-linear-gradient(bottom, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%);
        position: absolute;
        z-index: 4;
        top: 50%;
        transform: translateY(50%);
        @media (min-width: 768px) { height: 40%; }
      }

      &::after {
        content: "";
        width: 300px;
        height: 90%;
        background:url("../../assets/images/home/stepTexture.png");
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
      }

      .Viewer-Link {
        display: flex;
        position: absolute;
        right: -1em;
        bottom: 0em;
        z-index: 9;
        padding: 11px;
        cursor: pointer;
        border-radius: 50%;
        background: $levarBlue;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

        img {
          width: 30px;
          height: 30px;
          line-height: 1;
        }
      }

      .callOutBtn {
        position: absolute;
        z-index: 999;
        transform: translate(-50%, -50%);
        cursor: pointer;
        left: 50%;
        top: 100%;

        img {
          transition: all .3s ease-in-out;
          opacity: 1;
          width: 100%;

          &:hover {
            opacity: .8;
          }
        }
      }

    }
  }

}


/*====== WIDTH ======*/
.screenW {
  width: 100vw;
}
.fullW {
  width: 100%;
}
/*====== HEIGHT ======*/
.screenH {
  height: 60vh;
  @media screen and (min-width: 576px) {
    height: 75vh;
  }
  @media screen and (min-width: 768px) {
    height: 90vh;
  }
}
.fullH {
  height: 100%;
}
.height70 {
  height: 70px;
}
/*====== IMAGE ======*/
.imgCover {
  object-fit: cover;
}
/*====== OVERFLOW ======*/
.overflowH {
  overflow: hidden;
}
/*====== TRANSITIONS ======*/
.transition1l {
  transition: left .75s ease-in-out;
}
/*====== POSITION ======*/
.abs {
  position: absolute;
}
.rel {
  position: relative;
}
.left0 {
  left: 0px;
}
.left100vw {
  left: 100vw;
}
.leftM100vw {
  left: -100vw;
}
.bot0 {
  bottom: 0;
}
/*====== FLEX ======*/
.df {
  display: flex;
}
.row {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.fCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex1 {
  flex: 1;
}
.flex8 {
  flex: 8;
}
