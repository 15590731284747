
.BlogContent {
  padding: 2em;

  h1, h2, h3, h4 {
    margin: 0;
  }

  h1 {
    color: #4c4c4c;
    // font-family: $fontRobotoCond;
    padding: .5em 0;
  }

  h2, h3 {
    color: #4c4c4c;
    font-size: 1.75em;
    // font-family: $fontRobotoCond;
    font-weight: 300;
    // text-transform: uppercase;
    padding: .25em 0;
  }

  p, li {
    color: #4c4c4c;
    font-size: 1em;
    // font-family: $fontRoboto;
    font-weight: 300;
    line-height: 32px;
  }

  li {
    font-size: 17px;
    line-height: 28px;
    padding: .25em 0;
  }

  img {
    display: flex;
    margin: auto;
    width: 50%;
    height: auto;
  }

  iframe {
    height: 500px;
    display: inline-block;
    margin: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
    // font-family: $fontAbel;
    font-size: 1.2em;
    color: #2196F3;
  }
}
