
.nav__submenu {
    display: none;
}
  
.nav__menu-item:hover {
    .nav__submenu {
        display: block;
    }
}

ul.nav__menu {
    line-height: 45px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 0;

    li.nav__menu-item {
        padding: 0 2em 0 0;

        &:last-child {
            padding: 0
        }

        a {
            cursor: pointer;
            text-decoration: none;
            color: #fff;
            text-transform: none;
            font-size: 18px;
            transition: all .3s ease;
            opacity: 1;

            &:hover {
                opacity: .7;
            }
        }
    }
}

li.nav__menu-item {
    display: inline-block;
    position: relative;

&:hover {

    ul.nav__submenu {
        display: block;
        padding: 0;
        padding-left: 15px;
        left: -12px;

        li.nav__submenu-item {
            a {
                font-size: 16px;
            }
        }
    }
}
}
    
.nav__submenu {
    // font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    width: 200px;
    // margin-top: 6px;
    background: #1f1f1f;
}
    
.nav__submenu-item {
    list-style-type: none;
    padding: 0;
    
    &:hover {}
}

.levar-dialog-demo{
  // color: #ffffff;
  // text-decoration: none;
  // font-size: 1em;
  // text-transform: uppercase;
  // cursor: pointer;
  // letter-spacing: .07em;
  // opacity: 1;
  // transition: all .5s ease;
  // p {
  //   font-weight: 600;
  // };
  // &:hover {
  //   opacity: .5;
  // };
}

.levar-links {
  display: flex;


  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1em;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: .07em;
    opacity: 1;
    transition: all .5s ease;
    
    &:hover {
      opacity: .5;
    }
  }

  div { 
    color: #ffffff;
    text-decoration: none;
    font-size: 1em;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: .07em;
    opacity: 1;
    transition: all .5s ease;
    p {
      font-weight: 600;
    };
    &:hover {
      opacity: .5;
    };
  }
  

  &.desktop {
    a {
      padding-right: 2em;
      &:last-child {
        padding-right: 0em;
      }
    }
    div {
      // padding-right: 2em;
      // Added this for demo button in header

      @media(min-width: 1021px) {padding-right: 2em;}
      @media (max-width: 1020px) { display: none  };
      &:last-child {
        padding-right: 0em;
      }
    }
  }

  &.mobile {
    flex-direction: column;

    & > a {
      margin-bottom: .75em;
      font-size: 1.1em;
      border-bottom: solid 1px #5d5d5d;
      padding-bottom: .75em;
      padding-left: .25em;

      &:last-child {
        margin-bottom: 0em;
      }
    }
  }


  .MuiAccordion-root.Mui-expanded, .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
}
