/* #### Font Imports #### */
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;600;900&display=swap');
@import "./assets/scss/variables";

/* #### Component/Container/View Imports #### */
@import './App';

/* #### views #### */
@import './views/Home/Home';
@import './views/About/About';
@import './views/Pricing/Pricing';
@import './views/ARCommerce/ARCommerce';

/* #### components #### */
@import './components/Loading/Loading';
@import './components/Header/Header';
@import './components/Footer/Footer';
@import './components/Viewer/Viewer';

/* #### blog #### */
@import './views/BlogHome/BlogHome';
@import './views/BlogPost/BlogPost';


@import './views/Template/Sections/FeatureRightFadeBoxes';
/* #### Global Styling #### */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Overpass", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// h1 {
//   font-family: $fontOverpass;
// }



.container {
  width: 100%;
}

.row {
  flex: 1;
}

.Footer {
  // background: $FooterBG;
  // color: $levarWhite;
}

.levarH2 {
  // letter-spacing: .03em;
  // line-height: 1.25em;
}

.levarCopy {
  // @include fluid-type(300px, 1600px, 14px, 20px);
  // font-weight: 300;
  // font-family: $fontRoboto;
}

.levarBtn {
  // font-family: $fontAbel;
  background: #2196F3;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 75px;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 1;
  transition: all .3s ease;
  text-decoration: none;
  text-align: center;

  &:hover {
    opacity: .8;
  }
}
