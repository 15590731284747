.Footer {
  width: 100%;
  background: $levarBlack;
  // @media (min-width: 768px) { padding: 2em 4em }

  .Footer-Top {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    @media (min-width: 768px) { padding: 2em 4em; }

    .Levar-SubLinksTop {
        display: flex;
        padding-bottom: 1em;
        
        a:nth-child(2) {
            border-left: solid 1px #5f5f5f;
            border-right: solid 1px #5f5f5f;
          }
    }

    .Levar-SubLinks {
      display: flex;

      a:nth-child(2) {
        border-left: solid 1px #5f5f5f;
        border-right: solid 1px #5f5f5f;
      }

    }

    a {
        color: $levarBlue;
        transition: all .5s ease;
        text-decoration: none;
        padding: 0 1em;
        font-size: .7em;
        @media (min-width: 420px) { font-size: 1em; }

        &:hover {

        }
      }
  }

  .Footer-Middle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 2em 2em;
    @media (min-width: 768px) { padding: 2em 2em 4em; }
  }

  .Footer-Bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1b1b1b;
    padding: 1em 2em;
    @media (min-width: 768px) { padding: 1em 4em; }

    .row:nth-child(2) {
      text-align: center;
    }

    .row:nth-child(3) {
      text-align: right;
    }

    .row {
      display: flex;
      flex-direction: column;

      h5 {
        margin: 0;
        color: $levarWhite;
      }

      p {
        font-size: 7px;
        margin: 0;
        color: $levarWhite;
        @media (min-width: 420px) { font-size: 12px; }
      }

      a {
        margin-top: 1em;
        text-decoration: none;
        font-size: 1em;
        color: $levarWhite;
        padding-right: 1em;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .klaviyo-form-KDWZdH {
    position: relative;
    font-family: $fontOverpass !important;

    form {
      padding: 0 !important;
      margin: 0 !important;
      max-width: 388px !important;
    }

    p {
      text-align: left !important;
      font-size: 1.5em !important;
      line-height: 1 !important;
    }

    strong {
      line-height: 1 !important;
      letter-spacing: .04em !important;
      font-family: $fontOverpass !important;
      font-size: 18px !important;
      font-weight: 600 !important;
    }

    span {
      font-weight: 300 !important;
      font-size: 15px !important;
      line-height: 1 !important;
    }

    .klaviyo-form-KDWZdH {
      margin-top: 0px !important;
    }

    div {
      padding: 0 !important;
    }

    button {
      background: rgb(33, 150, 243) !important;
      color: #fff;
      // background: $primaryBtnColor;
      font-family: $fontOverpass !important;
    }
  }
}
